<template>
  <div class="container">
    <edit-header
      entity="ingredient"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :isNew="isNew"></edit-header>
    <form
      v-disable-all="!can(uiPermissions.INGREDIENTS_UPDATE)"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row">

        <div class="col">
          <div data-test="card-ingredient" class="card">
            <div class="card-header">
              <h3 class="card-title">Ingredient</h3>
              <div class="card-options">
                <label data-test="toggle-active" class="custom-switch m-0">
                  <input
                    v-model="item.is_active"
                    type="checkbox"
                    class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">Active</span>
                </label>
              </div>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="form-label">Name <span class="text-danger">*</span></label>
                <input
                  v-model="item.name"
                  type="text"
                  data-test="input-name"
                  class="form-control"
                  :class="{ 'is-invalid': invalid && response.error.name }"
                  required>
                <small v-if="invalid && response.error.name" class="error text-danger">
                  {{response.error.name.join(',')}}
                </small>
              </div>
              <div data-test="toggle-allowed-in-recipes" class="form-group">
                <label
                  v-b-tooltip.top.righttop="'Whether this ingredient will be displayed for recipe developers or not'"
                  class="form-label">
                  Allowed in recipes <span class="text-danger">*</span>
                </label>
                <label class="custom-switch">
                  <input
                    v-model="item.allowed_in_recipes"
                    type="checkbox"
                    class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                </label>
              </div>
              <div class="form-group">
                <label class="form-label">Measured in recipe by <span class="text-danger">*</span></label>
                <select
                  v-model="item.measured_in_recipe_by"
                  data-test="input-measured-in-recipe-by"
                  class="form-control"
                  :disabled="cannotEditField"
                  required>
                  <option value="">Select a measure</option>
                  <option value="weight">Weight</option>
                  <option value="quantity">Quantity</option>
                </select>
              </div>
              <div data-test="group-category" class="form-group">
                <label class="form-label">Category <span class="text-danger">*</span></label>
                <treeselect
                  :value="item.category"
                  :disabled="cannotEditField"
                  :normalizer="normalizer"
                  :options="paginated"
                  :required="true"
                  :class="{ 'is-invalid': invalid && !item.category }"
                  @close="onClose"
                  @open="onOpen"
                  @select="value => changeSelect(value)"
                  @search-change="query => onSearch(query)">
                  <div slot="value-label">{{(item.category || {}).name}}</div>
                  <div slot="after-list">
                    <div
                      v-show="hasNextPage"
                      ref="load"
                      class="text-center">
                      Loading more categories...
                    </div>
                  </div>
                </treeselect>
              </div>
              <div data-test="group-tags" class="form-group">
                <label class="form-label">Tags</label>
                <autocomplete
                  :disabled="cannotEditField"
                  :value="item.tags"
                  :list.sync="tags"
                  :nextPage="nextTagsPage"
                  :multiple="true"
                  :taggable="true"
                  label="name"
                  entity="tag"
                  placeholder="Select tags"
                  data-test="input-tags"
                  @input="selectTags"/>
              </div>
              <div class="form-group">
                <label class="form-label">Description</label>
                <textarea
                  v-model="item.description"
                  data-test="input-description"
                  class="form-control"
                  :disabled="cannotEditField">
                </textarea>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row">

        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="ingredient"
            :isNew="isNew"
            :submitting="submitting"
            :hide-delete="true"></edit-footer>
        </div>

      </div>
    </form>
  </div>
</template>

<script>

import Autocomplete from '@/components/Autocomplete';
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import edit from '@/mixins/edit';
import infiniteScroll from '@/mixins/infiniteScroll';
import {categories, mainIngredients, tags} from '@/services';
import Permissions from '@hellochef/shared-js/enums/Permissions';
import loadsRelations from '@hellochef/shared-js/mixins/loadsRelations';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    Autocomplete,
    EditFooter,
    EditHeader,
    Treeselect,
  },
  mixins: [
    edit,
    infiniteScroll,
    loadsRelations,
  ],
  data() {
    return {
      item: {
        is_active: true,
        allowed_in_recipes: false,
        category: null,
        description: '',
        measured_in_recipe_by: '',
        name: '',
      },
      with: [
        'category',
        'tags',
      ],
      tags: [],
      normalizer(node) {
        return {
          children: node ? node.sub_categories : [],
          label: node ? node.name : '',
        };
      },
      selected: null,
    };
  },
  computed: {
    cannotEditField() {
      return !this.isNew && !this.can(Permissions.INGREDIENTS_UPDATE);
    },
    route() {
      return `/ingredients/${this.item.id}`;
    },
  },
  created() {
    categories.getByParameters({page: 1}).then(result => this.data = result);
    tags.getByParameters({page: 1}).then(result => this.tags = result);
  },
  methods: {
    async nextTagsPage(page, query) {
      const params = {
        page,
      };
      if (query.length) params.query = query;
      return await tags.getByParameters(params);
    },
    selectTags(tags) {
      this.item.tags = tags.filter(tag => typeof tag !== 'string');
    },
    changeSelect(node) {
      this.item.category = node;
    },
    fetchData(id) {
      return mainIngredients.getById(id, {params: {...this.withRelations()}});
    },
    async handleDelete() {
      if (window.confirm('Deleting ingredient cannot be undone. Are you sure that you want to delete this ingredient?')) {
        await mainIngredients.deleteById(this.item.id);
        this.$router.push('/ingredients');
      }
    },
    async nextPage(page) {
      const result = await categories.getByParameters({limit: this.limit, page, query: this.search});
      return result;
    },
    async onSearch(query) {
      this.search = query;
      const result = await this.nextPage(1);
      Object.assign(this.meta, result.links);
      this.data.items = result.items;
    },
    submitData(item) {
      const payload = {
        category_id: item.category.id,
        description: item.description,
        measured_in_recipe_by: item.measured_in_recipe_by,
        name: item.name,
        is_active: item.is_active,
        allowed_in_recipes: item.allowed_in_recipes,
        tags: item.tags?.map(tag => {
          return tag.id;
        }) || [],
      };

      if (item.id) { payload.id = item.id; }

      return mainIngredients.saveOrUpdate(payload, {params: {...this.withRelations()}});
    },
  },
};

</script>
