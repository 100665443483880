<template>

  <div class="card">
    <div v-if="!onlyButton" class="card-body">
      <span v-if="dirty">You have unsaved changes&hellip;</span>
      <span v-else>No changes have been made. You can safely close this page.</span>
    </div>
    <div class="card-footer d-flex">
      <button
        v-if="!isNew && (canDelete || ($route.meta.permissions && can($route.meta.permissions.delete))) && !hideDelete"
        type="button"
        data-test="btn-delete"
        class="btn btn-secondary"
        :ignore-disable="ignoreDisable"
        :force-disable="forceDisable"
        :disabled="submitting"
        @click="$emit('delete')">
        Delete {{entity}}
      </button>
      <div class="ml-auto">
        <slot></slot>
        <button
          v-if="!isNew && (canUpdate || ($route.meta.permissions && can($route.meta.permissions.update)))"
          type="submit"
          class="btn btn-primary"
          :ignore-disable="ignoreDisable"
          :force-disable="forceDisable"
          :disabled="submitting || disabled"
          id="submit">
          {{submitTitle || 'Save Changes'}}
        </button>
        <button
          v-if="isNew && (canCreate || ($route.meta.permissions && can($route.meta.permissions.create)))"
          type="submit"
          class="btn btn-primary"
          :ignore-disable="ignoreDisable"
          :force-disable="forceDisable"
          :disabled="submitting || disabled"
          id="submit">
          {{createTitle || 'Submit'}}
        </button>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  props: [
    'dirty',
    'disabled',
    'entity',
    'hideDelete',
    'isNew',
    'submitting',
    'submitTitle',
    'createTitle',
    'onlyButton',
    'canCreate',
    'canUpdate',
    'canDelete',
    'ignoreDisable',
    'forceDisable',
  ],
};
</script>
