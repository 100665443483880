import cloneDeep from 'lodash/cloneDeep';

const NEW_PAGE = [null, undefined, 'new'];

export default {
  computed: {
    dirty() {
      return this.forceDirty; // TODO: intelligent dirty checking
    },
    isNew() {
      return (
        NEW_PAGE.includes(this.$route.params.id)
      );
    },
  },
  created() {
    this.reset();
  },
  data() {
    return {
      customScrollToError: false,
      error: false,
      errorMessage: '',
      forceDirty: false,
      invalid: false,
      invalidMessage: '',
      item: null,
      old: null,
      response: {
        data: null,
        error: null,
      },
      submitted: false,
      submitting: false,
      successMessage: '',
      loading: false,
    };
  },
  methods: {
    completed() {
      return true;
    },
    async reset() {
      if (!this.isNew) {
        this.loading = true;

        this.item = this.transformData(
          await this.fetchData(this.$route.params.id),
        ).item;

        this.old = Object.assign({}, this.item);

        this.$nextTick(() => (this.forceDirty = false));

        this.loading = false;
      }
      else {
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    async submit() {
      // flag to identify if the item is new or update
      const isNew = this.isNew === true;
      // flag to identify if the operation is successful
      let success = false;

      this.resetData();

      const form = this.$refs.form;
      const valid = (await this.validate()) && form.checkValidity();

      if (valid) {
        this.submitting = true;

        try {
          this.item = this.transformData(await this.submitData(this.item)).item;
          this.old = Object.assign({}, this.item);

          this.submitted = true;

          this.$nextTick(() => (this.forceDirty = false));

          this.$store.dispatch('auth/restore');

          if (this.$route.path !== this.route) {
            this.$router.replace({path: this.route});
          }

          success = true;
        }
        catch (ex) {
          if (ex.response && ex.response.status === 422) {
            this.invalid = true;
            this.invalidMessage = `Some values are still missing or invalid. Errors: ${Object.values(ex.response?.data?.errors).join(' ')}`;

            this.response = {
              data: this.response.data || this.item,
              error: ex.response.data.errors,
              old: cloneDeep(this.item), // copy it deep
            };
          }
          else {
            this.error = true;
            throw ex;
          }
        }
        finally {
          this.submitting = false;
          this.completed(success, isNew);
          window.scrollTo(0, 0);
        }
      }
      else {
        this.invalid = true;
      }

      form.classList.toggle('was-validated', this.invalid);

      if (!this.customScrollToError) { window.scrollTo(0, 0); }
    },
    transformData(result) {
      return result;
    },
    async validate() {
      return true;
    },
    async validateForm() {
      const form = this.$refs.form;
      const valid = (await this.validate()) && form.checkValidity();
      if (valid) return true;
      this.invalid = true;
      form.classList.toggle('was-validated', this.invalid);
      if (!this.customScrollToError) {
        window.scrollTo(0, 0);
      }
      return false;
    },
    resetData() {
      this.error = false;
      this.errorMessage = '';
      this.response = {
        data: null,
        error: {},
      };
      this.invalid = false;
      this.invalidMessage = '';
      this.submitted = false;
      this.successMessage = '';
    },
    resetDirty() {
      this.$nextTick(() => (this.forceDirty = false));
    },
  },
  watch: {
    $route() {
      this.reset();
    },
    item: {
      deep: true,
      handler() {
        this.forceDirty = true;
        this.invalid = false;
        this.invalidMessage = '';
      },
    },
  },
};
