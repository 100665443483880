<template>
  <v-select
    v-model="selected"
    ref="autoSelect"
    :class="customClass"
    :components="{Deselect, OpenIndicator}"
    :options="options"
    :filterable="local"
    :label="label"
    :disabled="disabled"
    :placeholder="placeholder"
    :multiple="multiple"
    :loading="loading"
    :clearable="clearable"
    class="custom-autocomplete"
    @close="onClose"
    @open="onOpen"
    @search="query => onSearch(query)">
    <div slot="list-footer">
      <div
        v-show="hasNextPage"
        ref="load"
        class="text-center list-spinner mt-2">
        <div class="spinner"></div>
      </div>
    </div>
    <template #no-options="{search}">
      <div
        v-if="taggable && search.length"
        class="cursor-pointer"
        @click.prevent="onCreate(search)">
        Click here to create <strong class="text-primary">{{search}}</strong>
      </div>
      <div v-else>Sorry, no matching options</div>
    </template>
  </v-select>
</template>

<script>
import debounce from 'debounce-promise';

export default {
  props: {
    customClass: Object,
    clearable: {
      default: true,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    entity: {
      type: String,
    },
    label: {
      type: String,
    },
    list: {
      default: () => [],
      type: [Array, Object],
    },
    local: {
      default: false,
      type: Boolean,
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    nextPage: {
      type: Function,
    },
    placeholder: {
      default: 'Select an item',
      type: String,
    },
    plural: {
      type: String,
    },
    value: {
      type: [String, Object, Array],
    },
    taggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

      Deselect: {
        render: createElement => createElement('span', {class: {'fe fe-x': true, 'toggle': true}}),
      },
      OpenIndicator: {
        render: createElement => createElement('span', {class: {'fe fe-chevron-down': true, 'toggle': true}}),
      },
      loading: false,
      observer: null,
      search: '',
    };
  },
  computed: {
    entities() {
      return this.plural || `${this.entity}s`;
    },
    hasNextPage() {
      return this.meta ? parseInt(this.meta.current_page) < parseInt(this.meta.last_page) : false;
    },
    meta() {
      return this.list.links;
    },
    options() {
      return this.list.items || [];
    },
    selected: {
      get() { return this.value; },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  mounted() {
    if (!this.local) { this.observer = new IntersectionObserver(this.infiniteScroll); }
  },
  methods: {
    debounceSearch: debounce(async function(query) {
      if (!this.local) {
        this.search = query;
        const result = await this.nextPage(1, query);

        this.list.items = result.items || [];
        this.list.links = result.links;

        this.loading = false;

        this.onOpen();
      }
    }, 700),
    infiniteScroll: debounce(async function([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;

        const result = await this.nextPage(parseInt(this.meta.current_page || 1) + 1, this.search);

        this.list.items = this.list.items.concat(result.items || []);
        this.list.links = result.links;

        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    }, 500),
    onClose() {
      if (!this.local) { this.observer.disconnect(); }
    },
    async onOpen() {
      if (this.hasNextPage && !this.local) {
        await this.$nextTick();
        if (!this.$refs.load) return;
        this.observer.observe(this.$refs.load);
      }
    },
    onSearch(query) {
      if (this.local) {
        return;
      }

      this.loading = true;
      this.debounceSearch(query);
    },
    onCreate(selected) {
      this.$emit('option:new', selected);
      this.$refs.autoSelect.search = '';
      this.$refs.autoSelect.onEscape();
    },
  },
};
</script>

<style scoped>
    .custom-autocomplete:not(.vs--single) >>> .vs__selected {
        border: 0;
        cursor: pointer;
        background: #e3f2fd;
        color: #039be5;
        font-size: 12px;
        border-radius: 2px;
        padding: 0.25rem;
        margin: 0.15rem;
        white-space: normal;
    }

    .custom-autocomplete:not(.vs--single) >>> .vs__dropdown-toggle {
        padding: .15rem .75rem;
    }

    .custom-autocomplete:not(.vs--single) >>> .vs__deselect {
        color: #039be5;
    }

    .custom-autocomplete:not(.vs--single) >>> .vs__selected-options input::placeholder {
        color: #ccc;
    }

    .custom-autocomplete >>> .vs__dropdown-option {

        white-space: normal;
    }

    .spinner,
    .spinner:before,
    .spinner:after {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0;
        left: 0;
        top: 0;
    }

    .list-spinner {
        display: flex;
        justify-content: center;
    }
</style>
