<template>
  <div class="no-print">
    <div class="page-header justify-content-between">
      <slot>
        <h1 v-if="entity" class="text-truncate mb-0">{{caption}}</h1>
      </slot>
      <div class="d-flex">
        <slot name="options"></slot>
      </div>
    </div>
    <div v-if="submitted" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="$emit('update:submitted', false)"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i> {{successMessage || 'Your changes have been saved successfully.'}}
    </div>
    <div v-show="invalid" class="alert alert-icon alert-warning alert-dismissible">
      <button
        type="button"
        class="close"
        @click="$emit('update:invalid', false)"></button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> {{invalidMessage || 'Some values are still missing or invalid. Please check them!'}}
    </div>
    <div v-show="error" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="$emit('update:error', false)"></button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i> {{errorMessage || 'Something went wrong; couldn\'t save your changes.'}}
    </div>
    <div v-show="!!warningMessage" class="alert alert-icon alert-warning alert-dismissible">
      <button
        type="button"
        class="close"
        @click="$emit('update:error', false)"></button>
      <i class="fe fe-clock mr-2" aria-hidden="true"></i> {{warningMessage}}
    </div>
  </div>
</template>

<script>

import startCase from 'lodash/startCase';

export default {
  props: [

    'entity',
    'error',
    'errorMessage',
    'warningMessage',
    'invalid',
    'invalidMessage',
    'isNew',
    'submitted',
    'successMessage',
  ],

  computed: {

    caption() {
      return `${this.isNew ? 'New ' : 'Edit '}${startCase(this.entity).toLowerCase()}`;
    },
  },
};

</script>
