export default {
  computed: {
    filtered() {
      return this.data.items || [];
    },
    hasNextPage() {
      return this.meta ? parseInt(this.meta.current_page) < parseInt(this.meta.last_page) : false;
    },
    limit() {
      return this.meta.per_page || 15;
    },
    meta() {
      return this.data.links;
    },
    paginated() {
      return this.filtered;
    },
  },
  data: () => ({
    data: {},
    loading: false,
    observer: null,
    search: '',
  }),
  methods: {
    async infiniteScroll([{isIntersecting, target}]) {
      if (isIntersecting) {
        this.loading = true;
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;

        const result = await this.nextPage(parseInt(this.meta.current_page) + 1);
        this.data.items = this.data.items.concat(result.items || []);
        Object.assign(this.meta, result.links);

        await this.$nextTick();
        ul.scrollTop = scrollTop;
        this.loading = false;
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
};
